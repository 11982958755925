import React from "react"
import Layout from "src/component/layout/layout"
import { Seo } from "src/component/common/seo"


export default function Company({ data }) {

    return (

        <Layout>
            <div className="p-privacy">
                <div className="p-privacy__inner c-inner-primary">
                    <div className="l-oldContent">
                        <div className="c-title01">
                            <h1 className="p-contact__title__text c-title01__text">セキュリティポリシー</h1>
                        </div>
                        <div className="c-title01">
                            <h2 className="p-vision__title c-title01__text"><span className="is-english">情報セキュリティ方針</span></h2>
                        </div>
                        <div className="l-oldContent__inner">
                            <section className="c-section">
                                <div className="l-frame">
                                    <div className="t-privacy-wrap">
                                        <p>当社の事業である、“データサイエンス事業”を確実にするためには、情報セキュリティ及び個人情報の保護は欠かせないテーマであり、当社の全ての利害関係者に安心を提供し、信頼を獲得するために、ここに情報セキュリティ方針を確立します。</p>
                                    </div>
                                    <div className="t-privacy-wrap" style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                                        <span>1.</span>
                                        <p>情報セキュリティに関する目標を明確に定め、目標達成のための PDCA を通じて効果的な情報セキュリティマネジメントシステム（ISMS）を実現します。</p>
                                    </div>
                                    <div className="t-privacy-wrap" style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                                        <span>2.</span>
                                        <p>個人情報保護法を含む、法令・規制要求事項及び個人情報の本人を含む、利害関係者からの要求事項など、当社の情報セキュリティマネジメントシステム（ISMS）に適用される要求事項を順守することを確実にします。</p>
                                    </div>
                                    <div className="t-privacy-wrap" style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                                        <span>3.</span>
                                        <p>定期的な情報セキュリティリスクアセスメント、内部監査、マネジメントレビューの活動や是正処置による改善を通じて、当社の情報セキュリティマネジメントシステム（ISMS）を継続的に改善します。</p>
                                    </div>

                                    <div style={{ textAlign: "right", lineHeight: "30px" }}>
                                        <p>2022 年 4 月 1 日<br />株式会社 DATAFLUCT<br />代表取締役<br />久米村 隼人</p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>

    )
}

export function Head() {
    return (
        <Seo
            title="セキュリティポリシー"
            description=""
            pathname=""
        />
    )
}
